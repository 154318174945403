import $axios from '@/backend'
import Vue from 'vue'
import { sortArray, isCurrentState } from '@/utils'

const getDefaultState = () => {
    return {
        currentFiles: [],
        currentFolder: null,
        currentTables: [],
        currentColumns: [],
        previewColumnHeaders: [],
        previewData: [],
        selectedTableForPreview: null,
        filesFetching: false,
        tablesFetching: false,
        columnsFetching: false,
        previewFetching: false,
        applicationsFetching: false,
        applicationImageFetching: false,
        restoringSnapshot: false,
        pathArray: [''],
        currentTreeLevel: null,
        applications: [],
        fileAreaType: 'files',
        fileOpened: {},
        imageList: [],
        stagingObjects: { files: [], tables: [], applications: [] },
        lastUploadedFiles: [],
        showUploadSnackbar: false,
        currentFileUnderRevisionData: { fileName: null, fid: null, previousVersions: [] },
        snapshotQueries: [],
        queryPreviewData: { headers: [], tableData: [], sql: '', qid: null },
        latestQuery: null,
        fullQueryData: [],
        tablesPath: [],
        filePollingInterval: null,
        snapshotQuota: null,
        fetchingSnapshotQuota: false,
        exportedImages: [],
        exportedImagesFetching: false
    }
}
const snapshotStore = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetSnapshotState(state) {
            if (state.filePollingInterval) {
                clearInterval(state.filePollingInterval)
            }
            Object.assign(state, getDefaultState())
        },
        setCurrentFiles: function (state, currentFiles) {
            state.currentFiles = currentFiles
        },
        setCurrentFolder: function (state, currentFolder) {
            state.currentFolder = currentFolder
        },
        setSelectedTableForPreview(state, table) {
            state.selectedTableForPreview = table
        },
        clearCurrentFiles: function (state) {
            state.currentFiles = []
        },
        setFilesPollingInterval: function (state, interval) {
            state.filePollingInterval = interval
        },
        setSnapshotRestoreState(state, newVal) {
            state.restoringSnapshot = newVal
        },
        clearFilePollingInterval: function (state) {
            clearInterval(state.filePollingInterval)
        },
        emptyTableData: function (state, clearQueryData) {
            state.currentColumns = []
            state.previewColumnHeaders = []
            state.previewData = []
            state.queryPreviewData = { headers: [], tableData: [], sql: '', qid: null }
            if (clearQueryData) {
                state.fullQueryData = []
            }
        },
        setFullQueryData: function (state, queryFiles) {
            state.fullQueryData = queryFiles
        },
        setTablesPath: function (state, data) {
            state.tablesPath = data
        },
        setSnapshotQuotaData: function (state, newVal) {
            state.snapshotQuota = newVal
        },
        setCurrentTables: function (state, currentTables) {
            state.currentTables = sortArray(currentTables, 'short_id', 'ascending', true)
        },
        setQueryPreviewData: function (state, { data, sql, qid }) {
            if (data.length) {
                state.queryPreviewData = { headers: Object.keys(data[0]), tableData: data, sql, qid }
            } else {
                state.queryPreviewData = { headers: [], tableData: data, sql, qid }
            }
        },
        setSnapshotQueries: function (state, queryData) {
            const sortBtQid = sortArray(queryData, 'qid', 'descending', false)
            state.snapshotQueries = sortBtQid
            state.latestQuery = sortBtQid[0]
        },
        setCurrentColumns: function (state, currentColumns) {
            const sortedCols = sortArray(currentColumns, 'cid', 'ascending', false)
            state.currentColumns = sortedCols
        },
        setCurrentFileUnderRevisionData: function (state, { fileName, fid, prevData }) {
            state.currentFileUnderRevisionData.fileName = fileName
            state.currentFileUnderRevisionData.fid = fid
            state.currentFileUnderRevisionData.previousVersions = sortArray(prevData, 'snid', 'descending', false)
        },
        setPreviewData: function (state, { keys, data }) {
            state.previewColumnHeaders = keys
            state.previewData = data
        },
        updateImageNamesList: function (state, imageList) {
            state.imageList = imageList
        },
        setPathArray: function (state, newVal) {
            state.pathArray = newVal
        },
        appendPathArray: function (state, dir) {
            state.pathArray.push(dir)
        },
        setTreeLevel: function (state, level) {
            state.pathArray.splice(level)
            if (!state.pathArray.length) {
                state.pathArray = ['']
            }
            state.currentTreeLevel = level
        },
        setApplications: function (state, apps) {
            state.applications = sortArray(apps, 'aid', 'descending', false)
        },
        setExportedImages: function (state, images) {
            state.exportedImages = sortArray(images, 'deid', 'descending', false)
        },
        setFetchingSnapshotQuota: function (state, val) {
            state.fetchingSnapshotQuota = val
        },
        setFetching: function (state, { fetchingType, val }) {
            if (fetchingType === 'files') {
                state.filesFetching = val
            } else if (fetchingType === 'tables') {
                state.tablesFetching = val
            } else if (fetchingType === 'columns') {
                state.columnsFetching = val
            } else if (fetchingType === 'preview') {
                state.previewFetching = val
            } else if (fetchingType === 'applicationImages') {
                state.applicationImageFetching = val
            } else if (fetchingType === 'applications') {
                state.applicationsFetching = val
            } else if (fetchingType === 'exportedImages') {
                state.exportedImagesFetching = val
            }
        },
        setFolderType: function (state, type) {
            state.fileAreaType = type
        },
        setOpenedFile: function (state, { aid, path }) {
            Vue.set(state.fileOpened, aid, path)
        },
        emptyStageInSessionStorage(state, snid) {
            const stage = JSON.parse(window.sessionStorage.getItem('stagedObjects'))
            delete stage[snid]
            window.sessionStorage.setItem('stagedObjects', JSON.stringify(stage))
        },
        setStagedObjectsFromStore(state, snid) {
            const stage = JSON.parse(window.sessionStorage.getItem('stagedObjects'))
            if (Object.keys(stage).includes(snid)) {
                state.stagingObjects.files = stage[snid].files
                state.stagingObjects.tables = stage[snid].tables
                state.stagingObjects.applications = stage[snid].applications
            }
        },
        updateStagedObjectedInStore(state, snid) {
            if (window.sessionStorage.getItem('stagedObjects')) {
                const stage = JSON.parse(window.sessionStorage.getItem('stagedObjects'))
                stage[snid] = state.stagingObjects
                window.sessionStorage.setItem('stagedObjects', JSON.stringify(stage))
            } else {
                window.sessionStorage.setItem('stagedObjects', JSON.stringify({ snid: state.stagingObjects }))
            }
        },
        updateStagingObjects: function (state, { itemType, newItems, updateMode }) {
            if (itemType === 'file') {
                if (updateMode === 'add') {
                    const fids = state.stagingObjects.files.map(file => file.fid)
                    newItems.forEach(file => {
                        if (!fids.includes(file.fid)) {
                            state.stagingObjects.files.push(file)
                        }
                    })
                } else if (updateMode === 'remove') {
                    const fids = newItems.map(file => file.fid)
                    const filteredArray = state.stagingObjects.files.filter(function (file) {
                        return fids.indexOf(file.fid) < 0
                    })
                    state.stagingObjects.files = filteredArray
                }
            } else if (itemType === 'table') {
                if (updateMode === 'add') {
                    const tids = state.stagingObjects.tables.map(table => table.tid)
                    newItems.forEach(table => {
                        if (!tids.includes(table.tid)) {
                            state.stagingObjects.tables.push(table)
                        }
                    })
                } else if (updateMode === 'remove') {
                    const tids = newItems.map(table => table.tid)
                    const filteredArray = state.stagingObjects.tables.filter(function (table) {
                        return tids.indexOf(table.tid) < 0
                    })
                    state.stagingObjects.tables = filteredArray
                }
            } else if (itemType === 'application') {
                if (updateMode === 'add') {
                    const aids = state.stagingObjects.applications.map(application => application.aid)
                    newItems.forEach(application => {
                        if (!aids.includes(application.aid)) {
                            state.stagingObjects.applications.push(application)
                        }
                    })
                } else if (updateMode === 'remove') {
                    const aids = newItems.map(application => application.aid)
                    const filteredArray = state.stagingObjects.applications.filter(function (application) {
                        return aids.indexOf(application.aid) < 0
                    })
                    state.stagingObjects.applications = filteredArray
                }
            }
        },
        updateLastUploadedFiles: function (state, { newData, updateMode }) {
            if (updateMode === 'add') {
                state.lastUploadedFiles = state.lastUploadedFiles.concat(newData)
            } else if (updateMode === 'clear') {
                state.lastUploadedFiles = newData
            }
        },
        setShowUploadSnackbar: function (state, newVal) {
            state.showUploadSnackbar = newVal
        },
        clearStagingObjects: function (state, itemType) {
            if (itemType === 'files') {
                state.stagingObjects.files = []
            } else if (itemType === 'tables') {
                state.stagingObjects.tables = []
            } else if (itemType === 'applications') {
                state.stagingObjects.applications = []
            } else if (itemType === 'all') {
                state.stagingObjects.files = []
                state.stagingObjects.tables = []
                state.stagingObjects.applications = []
            }
        }
    },
    actions: {
        setPathArray: function (context, newVal) {
            context.commit('setPathArray', newVal)
        },
        setStagedObjectsFromStore: function (context, snid) {
            context.commit('setStagedObjectsFromStore', snid)
        },
        resetSnapshotState: function (context) {
            context.commit('resetSnapshotState')
        },
        fetchSnapshotQuotaData: async function (context, snid) {
            context.commit('setFetchingSnapshotQuota', true)
            try {
                const { data } = await $axios.post(`snapshots/${snid}/quota_usage`)
                context.commit('setSnapshotQuotaData', data)
            } catch (error) {
                context.commit('setSnapshotQuotaData', error)
            } finally {
                context.commit('setFetchingSnapshotQuota', false)
            }
        },
        fetchApplications: function (context, snid) {
            context.commit('setFetching', { fetchingType: 'applications', val: true })
            $axios
                .get(`snapshots/${snid}/applications?deleting=true`)
                .then(function (response) {
                    const extendedApps = response.data.map(app => {
                        return app
                    })
                    context.commit('setApplications', extendedApps)
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingType: 'applications', val: false })
                })
        },
        fetchExportedImages: function (context, { snid, query = '' }) {
            context.commit('setFetching', { fetchingType: 'exportedImages', val: true })
            $axios
                .get(`/snapshots/${snid}/list_exports${query}`)
                .then(function (response) {
                    context.commit('setExportedImages', response.data)
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingType: 'exportedImages', val: false })
                })
        },
        fetchQueries: function (context, snid) {
            return $axios.get(`snapshots/${snid}/queries`).then(function (response) {
                context.commit('setSnapshotQueries', response.data)
            })
        },
        setCurrentFileUnderRevisionData: function (context, { fileName, fid }) {
            return $axios.get(`/files/${fid}/list_versions`).then(response => {
                context.commit('setCurrentFileUnderRevisionData', { fileName, fid, prevData: response.data })
            })
        },
        setTablesPath: function (context, snid) {
            $axios.get(`/snapshots/${snid}/tables_path`).then(response => {
                context.commit('setTablesPath', response.data)
            })
        },
        fetchFullQueryData: function (context, qid) {
            return $axios
                .get(`/queries/${qid}`)
                .then(response => {
                    context.commit('setFullQueryData', response.data)
                })
                .catch(() => {
                    context.dispatch('showSnackBar', {
                        snackBarText: 'Failed to fetch query data, try refreshing the page.',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
        },
        restoreSnapshotToCurrentState: function (context, { snid, createBackup }) {
            context.commit('setSnapshotRestoreState', true)
            $axios
                .put(`/snapshots/${snid}/restore_async`, { auto_backup: createBackup })
                .then(response => {
                    context.dispatch(
                        'showGlobalDialog',
                        {
                            dialogTitle: 'Snapshot being restored',
                            dialogText:
                                'Snapshot restoration has started, this might take a few minutes. You will also receive an email once the operation has finished.',
                            dialogAction: ['dismiss', 'fetch', 'snapshots'],
                            taskId: response.data.tkid
                        },
                        {
                            root: true
                        }
                    )
                    context.dispatch('instanceStore/fetchInstanceSnapshots', context.rootState.route.params.iid, {
                        root: true
                    })
                })
                .catch(() => {
                    context.dispatch(
                        'showSnackBar',
                        {
                            snackBarText: 'Snapshot restoration failed!',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        },
                        {
                            root: true
                        }
                    )
                })
                .finally(() => {
                    context.commit('setSnapshotRestoreState', false)
                })
        },
        setQueryPreviewData: function (context, { data, sql, qid }) {
            context.commit('setQueryPreviewData', { data, sql, qid })
        },
        openDirectory: function (context, { nextDirectory }) {
            context.commit('appendPathArray', nextDirectory)
        },
        setTreeLevel: function (context, { snid, level }) {
            context.commit('setTreeLevel', level)
        },
        setFolderType: function (context, type) {
            context.commit('setFolderType', type)
        },
        fetchCurrentFilesWithHistory: function (context, url) {
            $axios
                .get(`${url}?status=true`)
                .then(response => {
                    const files = response.data.filter(file => file.type !== 'broken_link')
                    const currentFolder = files.splice(
                        files.findIndex(file => file.type === 'folder' && file.short_id === '.'),
                        1
                    )[0]
                    const currentPath = context.state.pathArray.join('/').substr(1)
                    if (
                        files.length &&
                        files[0].local_path === currentPath &&
                        files[0].area === context.state.fileAreaType &&
                        context.rootState.route.params.snid &&
                        files[0].snid.toString() === context.rootState.route.params.snid.toString()
                    ) {
                        context.commit('setCurrentFiles', files)
                        context.commit('setCurrentFolder', currentFolder)
                    }
                })
                .catch(error => {
                    console.log(error)
                    context.dispatch('showSnackBar', { snackBarText: 'Fetching files failed, try reloading the page.', snackBarIcon: 'error' }, { root: true })
                })
        },
        fetchCurrentFiles: function (context, { id, route, setFetchingStatus }) {
            if (id && route) {
                context.commit('setFetching', { fetchingType: 'files', val: setFetchingStatus })
                if (!['handin', 'handback'].includes(route.name)) {
                    context.dispatch('fetchSnapshotQuotaData', id)
                }
                context.dispatch('getUserInfo', null, { root: true }).finally(() => {
                    if (context.rootState.userInfo && context.rootState.userInfo.is_active && context.rootState.userInfo.is_sysadmin !== undefined) {
                        if (context.state.filePollingInterval !== null) {
                            context.commit('clearFilePollingInterval')
                        }
                        if (route.params.localPath) {
                            context.dispatch('setPathArray', route.params.localPath.split('/'))
                        }
                        const localPath = context.state.pathArray.join('/')

                        let apiURL = ''
                        let root = ''
                        switch (route.name) {
                            case 'handin':
                                root = `/handin/${id}/fs/handin`
                                break
                            case 'handback':
                                root = `/handin/${id}/fs/handback`
                                break
                            default:
                                root = `/snapshots/${id}/fs/${context.state.fileAreaType}`
                                break
                        }
                        apiURL = `${root}${localPath}`
                        if (context.state.fileAreaType === 'home') {
                            apiURL = `${root}_v2`
                            if (localPath) apiURL = `${apiURL}${localPath}`
                        }
                        return $axios
                            .get(apiURL)
                            .then(response => {
                                const files = response.data.filter(file => file.type !== 'broken_link')
                                const currentFolder = files.splice(
                                    files.findIndex(file => file.type === 'folder' && file.short_id === '.'),
                                    1
                                )[0]
                                context.commit('setCurrentFolder', currentFolder)
                                context.commit('setCurrentFiles', files)
                                context.commit('setFetching', { fetchingType: 'files', val: false })
                                if (!['handin', 'handback'].includes(route.name)) {
                                    context.dispatch('fetchCurrentFilesWithHistory', apiURL)
                                }
                            })
                            .catch(error => {
                                console.log(error)
                                context.commit('setFetching', { fetchingType: 'files', val: false })
                                if (context.state.currentTreeLevel !== 0) {
                                    context.dispatch('setTreeLevel', { snid: id, level: 0 })
                                    context.dispatch('fetchCurrentFiles', { id, route, setFetchingStatus: true })
                                }
                                if (context.getters.currentSnapshotData && context.getters.currentSnapshotData.filesystem_prefix) {
                                    context.dispatch('showSnackBar', { snackBarText: 'Files fetching failed', snackBarIcon: 'error' }, { root: true })
                                }
                            })
                    }
                })
            }
        },
        clearCurrentFiles: function (context) {
            context.commit('clearCurrentFiles')
        },
        clearFilePollingInterval: function (context) {
            context.commit('clearFilePollingInterval')
        },
        updateLastUploadedFiles: function (context, { newData, updateMode }) {
            context.commit('updateLastUploadedFiles', { newData, updateMode })
        },
        setShowUploadSnackbar: function (context, newVal) {
            context.commit('setShowUploadSnackbar', newVal)
        },
        emptyTableData: function (context, clearQueryData) {
            context.commit('emptyTableData', clearQueryData)
        },
        fetchCurrentTables: function (context, snid) {
            context.commit('setFetching', { fetchingType: 'tables', val: true })
            $axios
                .get(`/snapshots/${snid}/ts/tables`)
                .then(response => {
                    context.commit('setCurrentTables', response.data)
                    context.commit('setFetching', { fetchingType: 'tables', val: false })
                })
                .catch(error => {
                    if (!error.response || !error.response.data || error.response.data.code !== 'observer_instance_role') {
                        context.dispatch(
                            'showSnackBar',
                            { snackBarText: 'Failed to fetch table data, try refreshing the page.', snackBarIcon: 'error' },
                            { root: true }
                        )
                    }
                    context.commit('setFetching', { fetchingType: 'tables', val: false })
                })
        },
        fetchColumnAndPreviewData: function (context, { tid, tableName }) {
            context.dispatch('fetchTablePreviewData', tid)
            context.dispatch('fetchCurrentColumns', tid)
            context.commit('setSelectedTableForPreview', tableName)
        },
        fetchTablePreviewData: function (context, tid) {
            context.commit('setFetching', { fetchingType: 'preview', val: true })
            return $axios
                .get(`tables/${tid}/preview`)
                .then(response => {
                    if (response.data.length) {
                        const headers = Object.keys(response.data[0]).map(header => {
                            return { text: header.toLowerCase(), align: 'left', value: header }
                        })
                        context.commit('setPreviewData', { keys: headers, data: response.data })
                    } else {
                        context.commit('setPreviewData', { keys: [], data: [] })
                    }
                })
                .catch(() => {
                    context.dispatch(
                        'showSnackBar',
                        { snackBarText: 'Failed to fetch table preview data, try refreshing the page.', snackBarIcon: 'error' },
                        { root: true }
                    )
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingType: 'preview', val: false })
                })
        },
        fetchCurrentColumns: function (context, tid) {
            context.commit('setFetching', { fetchingType: 'columns', val: true })
            return $axios
                .get(`/tables/${tid}/columns`)
                .then(response => {
                    context.commit('setCurrentColumns', response.data)
                })
                .catch(() => {
                    context.dispatch(
                        'showSnackBar',
                        { snackBarText: 'Failed to fetch table column data, try refreshing the page.', snackBarIcon: 'error' },
                        { root: true }
                    )
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingType: 'columns', val: false })
                })
        },
        fetchCurrentImages: function (context, sid) {
            context.commit('setFetching', { fetchingType: 'applicationImages', val: true })
            $axios
                .get(`/spaces/${sid}/images`)
                .then(response => {
                    context.commit('updateImageNamesList', response.data)
                })
                .catch(error => {
                    context.dispatch(
                        'showSnackBar',
                        { snackBarText: 'Failed to fetch application data, try refreshing the page.', snackBarIcon: 'error' },
                        { root: true }
                    )
                    console.log(error)
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingType: 'applicationImages', val: false })
                })
        },
        updateStagingObjects: function (context, { itemType, newItems, updateMode }) {
            const snid = context.rootState.route.params.snid.toString()
            context.commit('updateStagingObjects', { itemType, newItems, updateMode })
            context.commit('updateStagedObjectedInStore', snid)
        },
        clearStagingObjects: function (context, itemType) {
            const snid = context.rootState.route.params.snid.toString()
            context.commit('clearStagingObjects', itemType)
            if (itemType === 'all') {
                context.commit('emptyStageInSessionStorage', snid)
            } else {
                context.commit('updateStagedObjectedInStore', snid)
            }
        }
    },
    getters: {
        snapshotLongNameById: (state, getters, rootState) => snid => {
            if (snid !== undefined && snid !== null && rootState.instanceStore.instanceSnapshots.length) {
                const snapshot = rootState.instanceStore.instanceSnapshots.find(snapshot => snapshot.snid.toString() === snid.toString())
                if (snapshot && snapshot.long_id) {
                    return snapshot.long_id
                }
            }
            return null
        },
        snapshotDescriptionById: (state, getters, rootState) => snid => {
            if (snid !== undefined && snid !== null && rootState.instanceStore.instanceSnapshots.length) {
                const snapshot = rootState.instanceStore.instanceSnapshots.find(snapshot => snapshot.snid.toString() === snid.toString())
                if (snapshot && snapshot.description) {
                    return snapshot.description
                }
            }
            return null
        },
        snapshotFilesystemPrefixById: (state, getters, rootState) => snid => {
            if (snid !== undefined && snid !== null && rootState.instanceStore.instanceSnapshots.length) {
                const snapshot = rootState.instanceStore.instanceSnapshots.find(snapshot => snapshot.snid.toString() === snid.toString())
                if (snapshot && snapshot.filesystem_prefix) {
                    return snapshot.filesystem_prefix
                }
            }
            return null
        },
        isDevelopment: (state, getters, rootState) => {
            if (getters.developmentSnapshot && rootState.route.params.snid !== undefined) {
                return getters.developmentSnapshot.snid.toString() === rootState.route.params.snid.toString()
            } else {
                return false
            }
        },
        developmentSnapshot: (state, getters, rootState) => {
            if (rootState.instanceStore.instanceSnapshots) {
                return rootState.instanceStore.instanceSnapshots.filter(snapshot => isCurrentState(snapshot.short_id))[0]
            } else {
                return []
            }
        },
        currentSnapshotData: (state, getters, rootState) => {
            if (rootState.instanceStore.instanceSnapshots) {
                return rootState.instanceStore.instanceSnapshots.find(snapshot => snapshot.snid.toString() === rootState.route.params.snid.toString())
            } else {
                return {}
            }
        },
        nonDevelopmentSnapshots: (state, getters, rootState) => {
            if (rootState.instanceStore.instanceSnapshots) {
                return rootState.instanceStore.instanceSnapshots.filter(snapshot => !isCurrentState(snapshot.short_id))
            } else {
                return []
            }
        },
        currentFileLocalPath: state => {
            if (state.pathArray && state.pathArray.length > 1) {
                return state.pathArray.join('/')
            }
        },
        isStagingEmpty: state => {
            return !state.stagingObjects.files.length && !state.stagingObjects.tables.length && !state.stagingObjects.applications.length
        },
        appLongNameById: state => aid => {
            const apps = state.applications.filter(app => app.aid === parseInt(aid, 10))
            if (apps.length) {
                return apps[0].long_id
            }
            return null
        },
        tableShortNameById: state => tid => {
            const tables = state.currentTables.filter(table => table.tid === parseInt(tid, 10))
            if (tables.length) {
                return tables[0].short_id
            }
            return null
        }
    }
}

export default snapshotStore
