const TheInvitationReview = () => import('../views/TheInvitationReview')
const TheInvitationAccept = () => import('../views/TheInvitationAccept')
const TheInvitationCheckUser = () => import('../views/TheInvitationCheckUser')
const TheInvitationGeneratorSingle = () => import('../views/TheInvitationGeneratorSingle')
const GoogleSignin = () => import('../views/GoogleSignin')

export default [
    {
        path: '/invitations/review/:invid/:type?',
        name: 'invitation-review',
        components: {
            default: TheInvitationReview
        }
    },
    {
        path: '/invitations/accept/:invid/',
        name: 'invitation-accept',
        components: {
            default: TheInvitationAccept
        }
    },
    {
        path: '/invitations/userdetails/:invid/:type?',
        name: 'invitation-check-user',
        components: {
            default: TheInvitationCheckUser
        }
    },
    {
        path: '/enroll/:space/:token/:type?',
        name: 'invgen-single',
        components: {
            default: TheInvitationGeneratorSingle
        }
    },
    {
        path: '/trials/:token',
        name: 'trials',
        components: {
            default: TheInvitationGeneratorSingle
        }
    },
    {
        path: '/trials/:token/google',
        name: 'google-signin',
        components: {
            default: GoogleSignin
        }
    }
]
