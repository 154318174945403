import $axios from '@/backend.js'
import { sortArray } from '@/utils'
import { getInstance } from '@/auth/auth'
import { fetchTask } from '@/apis'
import { sortBy } from 'lodash'

const appStore = {
    namespaced: true,
    state: {
        userApps: [],
        deploymentStatus: [],
        recentApps: [{}, {}, {}],
        appType: 'jupyter',
        fetchingApps: false,
        lastStartedSessionId: null,
        stoppedSessions: [],
        startingApp: false,
        asOfTime: 0,
        nodePools: null,
        fetchingNodePools: false
    },
    mutations: {
        setDeploymentStatus: function (state, { deployments, asOfTime }) {
            const appsSortedById = sortArray(
                deployments.filter(d => {
                    return !state.stoppedSessions.includes(d)
                }),
                'aid',
                'descending',
                false
            )
            state.deploymentStatus = appsSortedById
            state.asOfTime = asOfTime
        },
        setUserApps: function (state, apps) {
            state.userApps = apps
        },
        setRecentApps: function (state, apps) {
            state.recentApps = apps
        },
        setNodePools: function (state, pools) {
            state.nodePools = pools
        },
        setAppType: function (state, appType) {
            state.appType = appType
        },
        setFetching: function (state, val) {
            state.fetchingApps = val
        },
        setFetchingNodePools: function (state, val) {
            state.fetchingNodePools = val
        },
        setLastStartedSessionId: function (state, sessionId) {
            state.lastStartedSessionId = sessionId
        },
        addStoppedSession: function (state, sessionId) {
            state.stoppedSessions.push(sessionId)
            const appsSortedById = sortArray(
                state.deploymentStatus.filter(d => {
                    return !state.stoppedSessions.includes(d)
                }),
                'aid',
                'descending',
                false
            )
            state.deploymentStatus = appsSortedById
        },
        setStartingApp: function (state, starting) {
            state.startingApp = starting
        }
    },
    actions: {
        fetchAllUserApps: function (context, { iid, startFirstApp }) {
            return $axios.post('/applications/list', { iid }).then(response => {
                context.commit('setUserApps', response.data)
                if (startFirstApp) {
                    context.dispatch('startApp', response.data[0].aid)
                }
            })
        },
        async fetchNodePools(context) {
            // Don't fetch nodepools if we already fetched
            if (Array.isArray(context.state.nodePools)) return

            context.commit('setFetchingNodePools', true)
            try {
                const { data } = await $axios.get('/applications/nodepools')
                context.commit('setNodePools', sortBy(data, ['credits_per_hour']))
            } catch (error) {
                context.commit('setNodePools', error)
            }
            context.commit('setFetchingNodePools', false)
        },
        fetchRecentApps: function (context, oid) {
            context.commit('setFetching', true)
            return $axios
                .post('/applications/really_quick', { oid })
                .then(response => {
                    // loop over list and find minimal iid for each aoid
                    const minIid = {}
                    let i
                    for (i = 0; i < response.data.length; i++) {
                        const complexId = `${response.data[i].aoid}_${response.data[i].space_id}`
                        if (response.data[i].instance_id < (minIid[complexId] || Infinity)) {
                            minIid[complexId] = response.data[i].instance_id
                        }
                    }

                    const recentApps = []
                    for (i = 0; i < response.data.length; i++) {
                        const complexId = `${response.data[i].aoid}_${response.data[i].space_id}`
                        if (response.data[i].instance_id <= minIid[complexId]) {
                            recentApps.push(response.data[i])
                        }
                    }
                    context.commit('setRecentApps', recentApps)
                })
                .catch(error => {
                    context.commit('setRecentApps', error)
                })
                .finally(() => {
                    context.commit('setFetching', false)
                })
        },
        updateDeploymentStatus: function (context) {
            if (getInstance().isAuthenticated) {
                const asOfTime = new Date().getTime()
                return $axios
                    .get('/proxy/app_status', {
                        withCredentials: true,
                        timeout: 60000,
                        params: context.rootState.route.params.iid ? { iid: context.rootState.route.params.iid } : {}
                    })
                    .then(function (response) {
                        if (asOfTime > context.state.asOfTime) {
                            context.commit('setDeploymentStatus', { deployments: response.data, asOfTime })
                        }
                    })
                    .catch(e => {
                        if (!e.response) {
                            context.dispatch(
                                'showSnackBar',
                                {
                                    snackBarText: 'There appears to be a connectivity issue to Nuvolos, please try reloading the page.',
                                    snackBarIcon: 'error'
                                },
                                {
                                    root: true
                                }
                            )
                        }
                    })
            }
        },
        startApp: async function (context, { aid, appUsername, appPwd }) {
            if (context.state.startingApp) {
                context.dispatch(
                    'showGlobalDialog',
                    {
                        dialogTitle: 'Application is already being started',
                        dialogText: 'An application is being started at the moment, please wait before starting another one.',
                        dialogAction: ['dismiss']
                    },
                    {
                        root: true
                    }
                )
                return
            }
            context.commit('setStartingApp', true)
            // check if the app supports HiDPI
            const launchedApp = context.state.userApps.filter(a => {
                return a.aid.toString() === aid.toString()
            })

            if (launchedApp.length < 1) {
                context.dispatch(
                    'showGlobalDialog',
                    {
                        dialogTitle: 'Application not found',
                        dialogText: 'This application does not seem to exist.',
                        dialogAction: ['returnToWelcomePage']
                    },
                    {
                        root: true
                    }
                )
            }

            const scaleFactor =
                window.devicePixelRatio >= 1.25 &&
                (window.innerWidth - 65) * window.innerHeight <= 1440 * 900 &&
                launchedApp.length &&
                launchedApp[0].app_type.endsWith('-hidpi')
                    ? 2
                    : 1

            try {
                const response = await $axios.get(`/applications/${aid}/config`)
                const { shared } = response.data
                const { data } = await $axios.post(`/proxy/${aid}/start_app_async`, {
                    dpi: 96 * scaleFactor,
                    screen_width: (window.innerWidth - 65) * scaleFactor,
                    screen_height: window.innerHeight * scaleFactor,
                    app_username: appUsername || '',
                    app_pwd: appPwd || '',
                    shared: context.rootState.route.query.grading === undefined ? shared : false,
                    mount_handback_to_files_for_hid: context.rootState.route.query.handback_hid,
                    mount_handback_for_bid: context.rootState.route.query.handback_bid
                })

                const taskResult = await fetchTask(data.tkid)
                if (taskResult instanceof Error) {
                    return taskResult
                } else {
                    return taskResult.data.meta_data.session_id
                }
            } catch (error) {
                console.log('Unable to start app: ', error)
                return error
            } finally {
                context.dispatch('updateDeploymentStatus').then(() => {
                    context.commit('setStartingApp', false)
                })
            }
        },
        stopApp: function (context, { aid, sessionId }) {
            if (!sessionId) {
                // get sessionId from first running session if undefined
                const a = context.state.deploymentStatus.find(d => {
                    return d.aid.toString() === aid.toString()
                })
                if (a) {
                    sessionId = a.session_id
                }
            }
            context.dispatch('showSnackBar', { snackBarText: 'Stopping application...', snackBarIcon: 'info', snackBarTimeout: 5000 }, { root: true })
            return $axios
                .post(`/proxy/${aid}/stop_app`, { session_id: sessionId || '' })
                .then(response => {
                    if ((sessionId || '') === '') {
                        window.setTimeout(() => {
                            context.dispatch('updateDeploymentStatus')
                        }, 1000)
                    }
                    if (sessionId) {
                        context.commit('addStoppedSession', sessionId)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                    context.dispatch(
                        'showSnackBar',
                        { snackBarText: 'Error stopping application. Please try again later.', snackBarTimeout: 10000 },
                        { root: true }
                    )
                })
        },
        setCurrentAppType: function (context, { iid, aid }) {
            return context.dispatch('fetchAllUserApps', { iid, startFirstApp: false }).then(() => {
                const launchedApp = context.state.userApps.filter(a => {
                    return a.aid.toString() === aid.toString()
                })
                if (launchedApp && launchedApp.length) {
                    context.commit('setAppType', launchedApp[0].app_type)
                }
            })
        }
    },
    getters: {
        runningDeployments: state => {
            return state.deploymentStatus.filter(d => {
                return d.replicas === d.available_replicas
            })
        },
        startingDeployments: state => {
            return state.deploymentStatus.filter(d => d.replicas > d.available_replicas)
        },
        stoppedApps: state => {
            if (state.userApps) {
                const runningAppsIds = state.deploymentStatus.map(app => app.aid)
                const stoppedApps = state.userApps.filter(app => !runningAppsIds.includes(app.aid.toString()))
                return stoppedApps
            } else {
                return []
            }
        },
        appStatus: state => aid => {
            const matchingDeployments = state.deploymentStatus.filter(d => {
                return d.aid.toString() === aid.toString()
            })
            if (matchingDeployments.length) {
                if (matchingDeployments[0].available_replicas > 0) {
                    return 'running'
                } else {
                    return 'starting'
                }
            } else {
                return 'stopped'
            }
        }
    }
}

export default appStore
