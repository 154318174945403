export default {
    methods: {
        async install() {
            const ret = await window.deferredPrompt.prompt()
            if (ret.outcome === 'accepted') {
                localStorage.setItem('pwa-installed', true)
                window.setTimeout(() => {
                    window.moveTo(0, 0)
                    window.resizeTo(window.screen.availWidth, window.screen.availHeight)
                }, 250)
            }
        },
        isPWA() {
            return window.matchMedia('(display-mode: standalone)').matches
        },
        isPWAInstalled() {
            return localStorage.getItem('pwa-installed')
        }
    }
}
