import Vue from 'vue'
import Vuex from 'vuex'
import store from 'store'
import tourStore from './tourStore'
import orgStore from './modules/organization/store'
import homeStore from './modules/home/store'
import spaceStore from './modules/space/store'
import instanceStore from './modules/instance/store'
import snapshotStore from './modules/snapshot/store'
import appStore from './modules/application/store'
import userStore from './modules/user/store'
import $axios from './backend.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        snackBar: false,
        snackBarText: 'default text',
        snackBarIcon: 'check_circle',
        snackBarLabel: 'Close',
        snackBarProgress: false,
        globalDialog: false,
        globalDialogTitle: '',
        globalDialogText: '',
        globalDialogAction: [],
        globalDialogTaskId: null,
        options: null,
        userOrgs: [],
        currentOrgUsers: [],
        fetchingOrgs: false,
        currentOrg: 0,
        currentOrgData: null,
        userInfo: null,
        userMetadata: null,
        snackBarTimer: null,
        openOrgSelectorDialog: false,
        fetchingUserInfo: false,
        trialInfo: null,
        bundleCreated: false,
        dimmedSidebar: false
    },
    mutations: {
        showSnackBar: function (state, { snackBarText, snackBarIcon, snackBarLabel, snackBarButtonOutline, snackBarProgress }) {
            state.snackBar = true
            state.snackBarText = snackBarText
            state.snackBarIcon = snackBarIcon
            state.snackBarProgress = snackBarProgress
            state.snackBarLabel = snackBarLabel
            state.snackBarButtonOutline = snackBarButtonOutline
        },
        showGlobalDialog: function (state, { dialogTitle, dialogText, dialogAction, globalDialogTaskId, options }) {
            if (!Array.isArray(dialogAction)) {
                console.error('dialogActions should be an array')
                return
            }

            if (!state.globalDialog) {
                state.globalDialog = true
                state.globalDialogTitle = dialogTitle
                state.globalDialogText = dialogText
                state.globalDialogAction = dialogAction
                state.globalDialogTaskId = globalDialogTaskId
                state.options = options
            }
        },
        setSnackBarTimer: function (state, timer) {
            state.snackBarTimer = timer
        },
        resetSnackBarTimer: function (state) {
            if (state.snackBarTimer) {
                clearTimeout(state.snackBarTimer)
            }
        },
        setUserOrgs: function (state, userOrgs) {
            state.userOrgs = userOrgs
        },
        setFetching: function (state, val) {
            state.fetchingOrgs = val
        },
        setOpenOrgSelectorDialog: function (state, val) {
            state.openOrgSelectorDialog = val
        },
        setCurrentOrgData: function (state, newData) {
            state.currentOrgData = newData
        },
        hideSnackBar: function (state) {
            state.snackBar = false
        },
        hideGlobalDialog: function (state) {
            state.globalDialog = false
            state.globalDialogTaskId = null
        },
        setFetchingUserInfo: function (state, status) {
            state.fetchingUserInfo = status
        },
        setUserInfo: function (state, { userInfo, userMetadata }) {
            state.userInfo = userInfo
            state.userMetadata = userMetadata
        },
        setUserMetadata: function (state, properties) {
            try {
                $axios
                    .patch('/users/metadata', {
                        user_metadata: properties
                    })
                    .then(() => {
                        state.userMetadata = { ...state.userMetadata, ...properties }
                    })
            } catch (error) {
                return error
            }
        },
        setTrialInfo: function (state, trialInfo) {
            state.trialInfo = trialInfo
        },
        setBundleCreated: function (state, val) {
            state.bundleCreated = val
        },
        toggleSidebarBrightness(state) {
            state.dimmedSidebar = !state.dimmedSidebar
        }
    },
    actions: {
        showSnackBar: function (context, { snackBarTimeout, snackBarText, snackBarIcon, snackBarLabel, snackBarProgress }) {
            context.commit('resetSnackBarTimer')
            context.commit('showSnackBar', { snackBarText, snackBarIcon, snackBarLabel, snackBarProgress })
            context.commit(
                'setSnackBarTimer',
                setTimeout(() => {
                    context.commit('hideSnackBar')
                }, snackBarTimeout || 10000)
            )
        },
        showGlobalDialog: function (context, { dialogTitle, dialogText, dialogAction, taskId, options }) {
            context.commit('showGlobalDialog', { dialogTitle, dialogText, dialogAction, globalDialogTaskId: taskId, options })
        },
        hideSnackBar: function (context) {
            context.commit('hideSnackBar')
        },
        hideGlobalDialog: function (context) {
            context.commit('hideGlobalDialog')
        },
        fetchUserOrgs: function (context) {
            context.commit('setFetching', true)
            $axios
                .post('/orgs/find', {})
                .then(response => {
                    let orgData
                    const latestOrg = context.state.userMetadata?.latestOrg?.toString()
                    const oids = response.data.map(org => org.oid.toString())
                    if (context.state.currentOrgData && oids.includes(context.state.currentOrgData.oid.toString())) {
                        orgData = context.state.currentOrgData
                    } else if (latestOrg && oids.includes(latestOrg)) {
                        const latestOrgData = response.data.filter(org => org.oid.toString() === latestOrg)
                        orgData = latestOrgData.length ? latestOrgData[0] : response.data[0]
                    } else {
                        orgData = response.data[0]
                    }
                    context.commit('setCurrentOrgData', orgData)
                    context.commit('setUserOrgs', response.data)
                })
                .finally(() => {
                    context.commit('setFetching', false)
                })
        },
        setOpenOrgSelectorDialog: function (context, val) {
            context.commit('setOpenOrgSelectorDialog', val)
        },
        setCurrentOrgData: function (context, newData) {
            if (newData !== undefined) {
                if (context.state.userOrgs && context.state.userOrgs.length > 1 && !context.state.userMetadata?.latestOrg) {
                    context.commit('setOpenOrgSelectorDialog', true)
                }
                context.commit('setCurrentOrgData', newData)
                context.commit('setUserMetadata', { latestOrg: newData.oid })
            }
        },
        getTrialInfo(context) {
            return $axios
                .get('/trials/current')
                .then(response => {
                    context.commit('setTrialInfo', response.data)
                })
                .catch(error => {
                    context.commit('setTrialInfo', null)
                    return error
                })
        },
        getUserInfo: function (context) {
            context.commit('setFetchingUserInfo', true)
            return $axios
                .get('/users/current')
                .then(async response => {
                    let userMetadata = null
                    try {
                        const { data } = await $axios.get('/users/metadata')
                        userMetadata = data
                    } catch (error) {
                        context.commit('setUserInfo', { userInfo: null, userMetadata: null })
                    }
                    if (typeof response.data === 'object' && response.data.is_active) {
                        context.commit('setUserInfo', { userInfo: response.data, userMetadata })
                        if (response.data.email) {
                            store.set('nvLastEmail', response.data.email)
                        }
                    } else if (context.state.route.params.invid === undefined) {
                        context.commit('setUserInfo', { userInfo: null, userMetadata: null })
                    }
                    context.commit('setFetchingUserInfo', false)
                    if (response.data && response.data.is_trial_user) {
                        this.dispatch('getTrialInfo')
                    }
                })
                .catch(error => {
                    if (error.response) {
                        // Request made and server responded
                        console.log('ERROR RESPONSE(data):', error.response.data)
                        console.log('ERROR RESPONSE(status):', error.response.status)
                        console.log('ERROR RESPONSE(header):', error.response.headers)
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log('ERROR REQUEST:', error.request)
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('ERROR ELSE:', error.message)
                    }
                    context.commit('setUserInfo', { userInfo: null, userMetadata: null })
                    context.commit('setFetchingUserInfo', false)
                })
        }
    },
    modules: {
        orgStore,
        homeStore,
        spaceStore,
        instanceStore,
        snapshotStore,
        appStore,
        userStore,
        tourStore
    }
})
